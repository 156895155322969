/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import C_SchemaCard from "../../2_components/schemaCard/C_SchemaCard";
import configOpts from "./configOpts";

const S_ConfiguratorResults = () => {
  // add instance of appstate
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const [accordion, setAccordion] = useState(true);
  const [accordionMain, setAccordionMain] = useState(true);

  useEffect(() => {
    let conf1Vars = {
      ...appState,
      currentPath: "/ui/configurator-results",
      outputName: "ConfiguratorResults",
      headerName: "Configurator Results",
      tags: null,
      description: "The Configurator Results Section displays system information for the user.",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/accordion.component.js",
        "../../js/configuratorResults.section.js"
      ],
      specs: [
        [
          "Configurator Results Card Icon (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, Large: Width and Height: any as long as the ratio is 1:1"
          ]
        ],
        ["Configurator Results Card Icon Image Type", ["SVG"]],
        [
          "Slot Image (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, Large: Width: 70px, Height: 32px"]
        ],
        ["Slot Image Type", ["SVG"]],
        ["Custom Heading", ["A <h1> consists of string text"]],
        ["Heading", ["A <h2> consists of string text"]],
        ["Title", ["A <h3> consists of string text with h5 styling"]],
        ["Sub Titles", ["A <h4> consists of string text with h6 styling"]],
        ["Copy", ["A <p> consists of string text"]],
        ["Link CTA", ["An <a> consists of string text"]],
        ["Value", ["A <span> consists of numeric value"]],
        ["Unit", ["A <span> consists of string text"]]
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ESJH_Ua3qTtPnAr7CxnklSwBLn_tDmadQJRKDmFsyKUWQg?e=fttxVv",
        comments: null
      }
    };
    setAppState(conf1Vars);
    setContentOptions(configOpts);
    setDimensions({
      ...dimensions,
      viewHeight: "895",
      hardcodeHeight: false,
      width: window.innerWidth - 24
    });
  }, []);

  useEffect(() => {
    if (accordion || accordionMain)
      setDimensions({ ...dimensions, width: dimensions.width - 0.25 });
    if (!accordion || !accordionMain)
      setDimensions({ ...dimensions, width: dimensions.width + 0.25 });
  }, [accordion, accordionMain]);

  // const getPanelHeight = (panel) => {
  //   if (panel && panel !== null && panel.current) {
  //     const clone = panel.current.cloneNode(true);
  //     let pHeight = "";
  //     clone.style.position = "absolute";
  //     clone.style.visibility = "hidden";
  //     document.body.appendChild(clone);
  //     pHeight = clone.offsetHeight;
  //     console.log("pHeight: ", pHeight);
  //     document.body.removeChild(clone);
  //     return pHeight;
  //   }
  // };

  if (!appState) return "...Loading Configurator Results Section";

  return (
    <section className={`s-configuratorResults t-silver`} id="configuratorResults0">
      {contentOptions.custom && contentOptions.custom.checkbox == true ? (
        <div className="c-heading u-pb0">
          <div className="c-heading__block">
            <div className="c-heading__block__header">
              <h1>
                {contentOptions.customheading &&
                  contentOptions.customheading.field[contentOptions.currentLang]}
              </h1>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className={`c-accordion s-configuratorResults__accordion ${
          contentOptions.custom && contentOptions.custom.checkbox == true ? "l-inner" : ""
        }`}
      >
        <h2
          className={`c-accordion__tab  s-configuratorResults__accordion__heading ${
            accordionMain ? " c-accordion__tab--active" : ""
          }`}
        >
          <button
            aria-controls=""
            aria-expanded="true"
            onClick={() => setAccordionMain(!accordionMain)}
          >
            {contentOptions.heading && contentOptions.heading.field[contentOptions.currentLang]}
            <svg className="c-accordion__tab__icon" viewBox="0 0 14 8" aria-hidden="true">
              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
            </svg>
          </button>

          {contentOptions.custom && contentOptions.custom.checkbox == true ? (
            <div className="s-configuratorResults__accordion__heading__search">
              <a href="#anchorLink" title="Anchor Title">
                <span>New Search</span>
                <svg height="32" width="32" viewBox="0 0 32 32">
                  <path
                    d="M23,19.7017046 C23,20.4187334 22.4187334,21 21.7017046,21 C20.9846759,21 20.4034092,20.4187334 20.4034092,19.7017046 L20.4029853,13.688 L11.2131908,22.6271968 C10.7020949,23.1242688 9.88821735,23.1242738 9.37711523,22.6272082 C8.88401294,22.1476481 8.87303486,21.3591494 9.352595,20.8660471 C9.36065107,20.8577635 9.36882203,20.8495925 9.3771055,20.8415363 L18.9549853,11.525 L12.2626676,11.5253351 C11.5653155,11.5253351 11,10.9600196 11,10.2626676 C11,9.56531552 11.5653155,9 12.2626676,9 L23,9 L23,19.7017046 Z"
                    id="Combined-Shape"
                    transform="translate(15.999993, 16.000002) rotate(-315.000000) translate(-15.999993, -16.000002) "
                  ></path>
                </svg>
              </a>
            </div>
          ) : (
            ""
          )}
        </h2>
        <div
          className="c-accordion__panel s-configuratorResults__accordion__results"
          id=""
          aria-labelledby=""
          style={{
            maxHeight: accordionMain ? "999vh" : null
          }}
        >
          <div className="c-accordion__panel__content">
            <div className="l-inner">
              <div className="s-configuratorResults__accordion__results__grid s-configuratorResults__accordion__results__grid--4Items">
                {contentOptions.memorytitle && contentOptions.memorytitle.checkbox == true ? (
                  <div className="c-configuratorResultsCard">
                    <div className="c-accordion c-accordion--noOutline">
                      <div
                        className="c-accordion__tab c-configuratorResultsCard__header c-accordion__tab--active"
                        id=""
                      >
                        <button aria-controls="" aria-expanded="true">
                          <svg
                            className="c-configuratorResultsCard__header__icon"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                          >
                            <use
                              xlink="http://www.w3.org/1999/xlink"
                              xlinkHref="/images/icons-map.svg#results-memory"
                            ></use>
                          </svg>
                          <h3 className="u-h5">
                            {contentOptions.memorytitle.field[contentOptions.currentLang]}
                          </h3>
                          <svg
                            className="c-accordion__tab__icon"
                            width="14"
                            height="8"
                            aria-hidden="true"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </div>
                      <div
                        className="c-accordion__panel c-configuratorResultsCard__info"
                        id=""
                        aria-labelledby=""
                        style={{
                          maxHeight: "999vh"
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <ul className="u-list-unstyled">
                            {contentOptions.memorysubtitle1 &&
                            contentOptions.memorysubtitle1.checkbox == true ? (
                              <li>
                                <h4 className="u-h6">
                                  {contentOptions.memorysubtitle1.field[contentOptions.currentLang]}
                                </h4>
                                <p>
                                  {contentOptions.memorycopy1 &&
                                    contentOptions.memorycopy1.field[contentOptions.currentLang]}
                                </p>
                                <p>
                                  {contentOptions.memorycopy2 &&
                                    contentOptions.memorycopy2.field[contentOptions.currentLang]}
                                </p>
                              </li>
                            ) : (
                              ""
                            )}
                            {contentOptions.memorysubtitle2 &&
                            contentOptions.memorysubtitle2.checkbox == true ? (
                              <li>
                                <h4 className="u-h6">
                                  {contentOptions.memorysubtitle2.field[contentOptions.currentLang]}
                                </h4>
                                <p>20 GB with 4GB standard</p>
                                <p>24 GB with 8GB standard</p>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                          {contentOptions.memorycta && contentOptions.memorycta.checkbox == true ? (
                            <div className="c-configuratorResultsCard__info__cta">
                              <a href="#anchorLink" title="Anchor Title">
                                {contentOptions.memorycta &&
                                  contentOptions.memorycta.field[contentOptions.currentLang]}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          {contentOptions.memoryschema &&
                          contentOptions.memoryschema.checkbox == true ? (
                            <div
                              className="c-accordion c-configuratorResultsCard__info__bankSchema"
                              data-multiselect="false"
                            >
                              <h6
                                className={`c-accordion__tab ${
                                  accordion ? " c-accordion__tab--active" : ""
                                }`}
                                id=""
                                onClick={() => setAccordion(!accordion)}
                              >
                                <button aria-controls="" aria-expanded="false">
                                  <span>Show Bank Schema</span>
                                  <span>Hide Bank Schema</span>
                                  <svg
                                    className="c-accordion__tab__icon"
                                    viewBox="0 0 14 8"
                                    aria-hidden="true"
                                  >
                                    <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                                  </svg>
                                </button>
                              </h6>
                              <div
                                className="c-accordion__panel"
                                id=""
                                aria-labelledby=""
                                style={{
                                  maxHeight: accordion ? "999vh" : null
                                }}
                              >
                                <div className="c-accordion__panel__content">
                                  {contentOptions.memoryBankSchemaExample &&
                                  contentOptions.memoryBankSchemaExample.selected == "1" ? (
                                    <ul className="u-list-unstyled">
                                      <li>
                                        <p>FPO Demo:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <small>GRP #</small>
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP #</small>
                                                    <C_SchemaCard
                                                      value={
                                                        contentOptions.value &&
                                                        contentOptions.value.field
                                                      }
                                                      unit={
                                                        contentOptions.unit &&
                                                        contentOptions.unit.field
                                                      }
                                                    />
                                                  </td>
                                                  <td>
                                                    <small>GRP #</small>
                                                    <C_SchemaCard value="32" unit="MB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP #</small>
                                                    <C_SchemaCard value="128" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP #</small>
                                                    <C_SchemaCard value="2500" unit="KB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <p>16 GB Standard:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <small>GRP 8</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 7</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 6</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 5</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 4</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 3</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 2</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 1</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 1</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 2</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 3</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 4</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 5</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 6</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 7</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 8</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <p>32 GB Standard:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <small>GRP 8</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 7</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 6</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 5</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 4</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 3</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 2</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 1</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 1</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 2</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 3</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 4</small>
                                                    <C_SchemaCard value="2" unit="GB" />
                                                    <C_SchemaCard value="2" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <small>GRP 5</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 6</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 7</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <small>GRP 8</small>
                                                    <C_SchemaCard empty />
                                                    <C_SchemaCard empty />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  ) : contentOptions.memoryBankSchemaExample &&
                                    contentOptions.memoryBankSchemaExample.selected == "2" ? (
                                    <ul className="u-list-unstyled">
                                      <li>
                                        <p>SSD:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard empty />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  ) : contentOptions.memoryBankSchemaExample &&
                                    contentOptions.memoryBankSchemaExample.selected == "3" ? (
                                    <ul className="u-list-unstyled">
                                      <li>
                                        <p>CPU 1:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <p>CPU 2:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  ) : contentOptions.memoryBankSchemaExample &&
                                    contentOptions.memoryBankSchemaExample.selected == "4" ? (
                                    <ul className="u-list-unstyled">
                                      <li>
                                        <p>16GB Standard:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  ) : contentOptions.memoryBankSchemaExample &&
                                    contentOptions.memoryBankSchemaExample.selected == "5" ? (
                                    <ul className="u-list-unstyled">
                                      <li>
                                        <p>8GB Standard:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <p>16GB Standard:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  ) : contentOptions.memoryBankSchemaExample &&
                                    contentOptions.memoryBankSchemaExample.selected == "6" ? (
                                    <ul className="u-list-unstyled">
                                      <li>
                                        <p>SSD:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard empty />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard empty />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  ) : contentOptions.memoryBankSchemaExample &&
                                    contentOptions.memoryBankSchemaExample.selected == "6" ? (
                                    <ul className="u-list-unstyled">
                                      <li>
                                        <p>8GB Standard:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <p>16GB Standard:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  ) : (
                                    <ul className="u-list-unstyled">
                                      <li>
                                        <p>CPU 1:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <p>CPU 2:</p>
                                        <div className="c-table u-tableUnstyled u-tableCollapse c-configuratorResultsCard__info__bankSchema__table">
                                          <div className="c-table__main">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <C_SchemaCard value="8" unit="GB" />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {contentOptions.storagetitle && contentOptions.storagetitle.checkbox == true ? (
                  <div className="c-configuratorResultsCard">
                    <div className="c-accordion c-accordion--noOutline">
                      <div
                        className="c-accordion__tab c-configuratorResultsCard__header c-accordion__tab--active"
                        id=""
                      >
                        <button aria-controls="" aria-expanded="true">
                          <svg
                            className="c-configuratorResultsCard__header__icon"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                          >
                            <use
                              xlink="http://www.w3.org/1999/xlink"
                              xlinkHref="/images/icons-map.svg#results-storage"
                            ></use>
                          </svg>
                          <h3 className="u-h5">
                            {contentOptions.storagetitle.field[contentOptions.currentLang]}
                          </h3>
                          <svg
                            className="c-accordion__tab__icon"
                            width="14"
                            height="8"
                            aria-hidden="true"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </div>
                      <div
                        className="c-accordion__panel c-configuratorResultsCard__info"
                        id=""
                        aria-labelledby=""
                        style={{
                          maxHeight: "999vh"
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <ul className="u-list-unstyled">
                            <li>
                              <h4 className="u-h6">
                                {contentOptions.storagesubtitle1 &&
                                  contentOptions.storagesubtitle1.field[contentOptions.currentLang]}
                              </h4>
                              <p>
                                {contentOptions.storagecopy1 &&
                                  contentOptions.storagecopy1.field[contentOptions.currentLang]}
                              </p>
                              {contentOptions.storagecopy2 &&
                              contentOptions.storagecopy2.checkbox == true ? (
                                <p>
                                  {contentOptions.storagecopy2.field[contentOptions.currentLang]}
                                </p>
                              ) : (
                                ""
                              )}
                              {contentOptions.storagecopy3 &&
                              contentOptions.storagecopy3.checkbox == true ? (
                                <p>
                                  {contentOptions.storagecopy3.field[contentOptions.currentLang]}
                                </p>
                              ) : (
                                ""
                              )}
                              {contentOptions.storagecopy4 &&
                              contentOptions.storagecopy4.checkbox == true ? (
                                <p>
                                  {contentOptions.storagecopy4.field[contentOptions.currentLang]}
                                </p>
                              ) : (
                                ""
                              )}
                              {contentOptions.storagecopy5 &&
                              contentOptions.storagecopy5.checkbox == true ? (
                                <p>
                                  {contentOptions.storagecopy5.field[contentOptions.currentLang]}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                          {contentOptions.storagecta &&
                          contentOptions.storagecta.checkbox == true ? (
                            <div className="c-configuratorResultsCard__info__cta">
                              <a href="#anchorLink" title="Anchor Title">
                                {contentOptions.storagecta.field[contentOptions.currentLang]}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {contentOptions.expansiontitle && contentOptions.expansiontitle.checkbox == true ? (
                  <div className="c-configuratorResultsCard">
                    <div className="c-accordion c-accordion--noOutline">
                      <div
                        className="c-accordion__tab c-configuratorResultsCard__header c-accordion__tab--active"
                        id=""
                      >
                        <button aria-controls="" aria-expanded="true">
                          <svg
                            className="c-configuratorResultsCard__header__icon"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                          >
                            <use
                              xlink="http://www.w3.org/1999/xlink"
                              xlinkHref="/images/icons-map.svg#results-sd_card"
                            ></use>
                          </svg>
                          <h3 className="u-h5">
                            {contentOptions.expansiontitle.field[contentOptions.currentLang]}
                          </h3>
                          <svg
                            className="c-accordion__tab__icon"
                            width="14"
                            height="8"
                            aria-hidden="true"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </div>
                      <div
                        className="c-accordion__panel c-configuratorResultsCard__info"
                        id=""
                        aria-labelledby=""
                        style={{
                          maxHeight: "fit-content"
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <ul className="u-list-unstyled">
                            <li>
                              <p>
                                {contentOptions.expansioncopy1 &&
                                  contentOptions.expansioncopy1.field[contentOptions.currentLang]}
                              </p>
                              <p>
                                {contentOptions.expansioncopy2 &&
                                  contentOptions.expansioncopy2.field[contentOptions.currentLang]}
                              </p>
                            </li>
                          </ul>
                          {contentOptions.expansionmemorycta ||
                          contentOptions.expansionstoragecta ? (
                            <div className="c-configuratorResultsCard__info__cta">
                              {contentOptions.expansionmemorycta.checkbox == true ? (
                                <a href="#anchorLink" title="Anchor Title">
                                  {
                                    contentOptions.expansionmemorycta.field[
                                      contentOptions.currentLang
                                    ]
                                  }
                                </a>
                              ) : (
                                ""
                              )}
                              {contentOptions.expansionstoragecta.checkbox == true ? (
                                <a href="#anchorLink" title="Anchor Title">
                                  {
                                    contentOptions.expansionstoragecta.field[
                                      contentOptions.currentLang
                                    ]
                                  }
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {contentOptions.cputitle && contentOptions.cputitle.checkbox == true ? (
                  <div className="c-configuratorResultsCard">
                    <div className="c-accordion c-accordion--noOutline">
                      <div
                        className="c-accordion__tab c-configuratorResultsCard__header c-accordion__tab--active"
                        id=""
                      >
                        <button aria-controls="" aria-expanded="true">
                          <svg
                            className="c-configuratorResultsCard__header__icon"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                          >
                            <use
                              xlink="http://www.w3.org/1999/xlink"
                              xlinkHref="/images/icons-map.svg#results-cpu_chipset"
                            ></use>
                          </svg>
                          <h3 className="u-h5">
                            {contentOptions.cputitle &&
                              contentOptions.cputitle.field[contentOptions.currentLang]}
                          </h3>
                          <svg
                            className="c-accordion__tab__icon"
                            width="14"
                            height="8"
                            aria-hidden="true"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                          </svg>
                        </button>
                      </div>
                      <div
                        className="c-accordion__panel c-configuratorResultsCard__info"
                        id=""
                        aria-labelledby=""
                        style={{
                          maxHeight: "999vh"
                        }}
                      >
                        <div className="c-accordion__panel__content">
                          <ul className="u-list-unstyled">
                            <li>
                              <p>
                                {contentOptions.cpucopy1 &&
                                  contentOptions.cpucopy1.field[contentOptions.currentLang]}
                              </p>
                              <p>
                                {contentOptions.cpucopy2 &&
                                  contentOptions.cpucopy2.field[contentOptions.currentLang]}
                              </p>
                              <p>
                                {contentOptions.cpucopy3 &&
                                  contentOptions.cpucopy3.field[contentOptions.currentLang]}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {contentOptions.systempartnumberstitle &&
              contentOptions.systempartnumberstitle.checkbox == true ? (
                <div className="c-configuratorResultsCard">
                  <div className="c-accordion c-accordion--noOutline">
                    <div
                      className="c-accordion__tab c-configuratorResultsCard__header c-accordion__tab--active"
                      id=""
                    >
                      <button aria-controls="" aria-expanded="true">
                        <svg
                          className="c-configuratorResultsCard__header__icon"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                        >
                          <use
                            xlink="http://www.w3.org/1999/xlink"
                            xlinkHref="/images/icons-map.svg#results-part_number"
                          ></use>
                        </svg>
                        <h3 className="u-h5">
                          {contentOptions.systempartnumberstitle.field[contentOptions.currentLang]}
                        </h3>
                        <svg
                          className="c-accordion__tab__icon"
                          width="14"
                          height="8"
                          aria-hidden="true"
                          viewBox="0 0 14 8"
                        >
                          <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                        </svg>
                      </button>
                    </div>
                    <div
                      className="c-accordion__panel c-configuratorResultsCard__info"
                      id=""
                      aria-labelledby=""
                      style={{
                        maxHeight: "999vh"
                      }}
                    >
                      <div className="c-accordion__panel__content">
                        <div className="l-row">
                          <div className="l-row__col">
                            <p>
                              {contentOptions.systempartnumberscopy1 &&
                                contentOptions.systempartnumberscopy1.field}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {(contentOptions.importantnotestitle &&
          contentOptions.importantnotestitle.checkbox == true) ||
        (contentOptions.furytitle && contentOptions.furytitle.checkbox == true) ? (
          <div className="s-configuratorResults__additionalInfo">
            <div className="l-inner">
              {contentOptions.importantnotestitle &&
              contentOptions.importantnotestitle.checkbox == true ? (
                <div className="c-configuratorResultsCard c-configuratorResultsCard--additionalInfo">
                  <div className="c-configuratorResultsCard__header c-accordion__tab--active">
                    <svg
                      className="c-configuratorResultsCard__header__icon"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                    >
                      <use
                        xlink="http://www.w3.org/1999/xlink"
                        xlinkHref="/images/icons-map.svg#results-important_notes"
                      ></use>
                    </svg>
                    <h3 className="u-h5 u-txt-uppercase">
                      {contentOptions.importantnotestitle.field[contentOptions.currentLang]}
                    </h3>
                  </div>
                  <div className="c-configuratorResultsCard__info">
                    {contentOptions.importantNotesExample &&
                    contentOptions.importantNotesExample.selected == "1" ? (
                      <p>
                        {contentOptions.importantnotescopy1 &&
                          contentOptions.importantnotescopy1.field[contentOptions.currentLang]}
                      </p>
                    ) : contentOptions.importantNotesExample &&
                      contentOptions.importantNotesExample.selected == "2" ? (
                      <div className="l-row">
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.importantnotescopy1 &&
                                contentOptions.importantnotescopy1.field[
                                  contentOptions.currentLang
                                ]}
                            </li>
                          </ul>
                        </div>
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.importantnotescopy2 &&
                                contentOptions.importantnotescopy2.field[
                                  contentOptions.currentLang
                                ]}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="l-row">
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.importantnotescopy1 &&
                                contentOptions.importantnotescopy1.field[
                                  contentOptions.currentLang
                                ]}
                            </li>
                            <li>
                              {contentOptions.importantnotescopy2 &&
                                contentOptions.importantnotescopy2.field[
                                  contentOptions.currentLang
                                ]}
                            </li>
                          </ul>
                        </div>
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.importantnotescopy3 &&
                                contentOptions.importantnotescopy3.field[
                                  contentOptions.currentLang
                                ]}
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {contentOptions.furytitle && contentOptions.furytitle.checkbox == true ? (
                <div className="c-configuratorResultsCard c-configuratorResultsCard--additionalInfo">
                  <div className="c-configuratorResultsCard__header c-accordion__tab--active">
                    <svg
                      className="c-configuratorResultsCard__header__icon"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                    >
                      <use
                        xlink="http://www.w3.org/1999/xlink"
                        xlinkHref="/images/icons-map.svg#results-important_notes"
                      ></use>
                    </svg>
                    <h3 className="u-h5 u-txt-uppercase">
                      {contentOptions.furytitle &&
                        contentOptions.furytitle.field[contentOptions.currentLang]}
                    </h3>
                  </div>
                  <div className="c-configuratorResultsCard__info">
                    {contentOptions.furyExample && contentOptions.furyExample.selected == "1" ? (
                      <>
                        <p>
                          {contentOptions.furycopy1 &&
                            contentOptions.furycopy1.field[contentOptions.currentLang]}{" "}
                          <a href="#">Link</a>
                        </p>
                      </>
                    ) : contentOptions.furyExample && contentOptions.furyExample.selected == "2" ? (
                      <div className="l-row">
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.furycopy1 &&
                                contentOptions.furycopy1.field[contentOptions.currentLang]}
                            </li>
                          </ul>
                        </div>
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.furycopy2 &&
                                contentOptions.furycopy2.field[contentOptions.currentLang]}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : contentOptions.furyExample && contentOptions.furyExample.selected == "3" ? (
                      <div className="l-row">
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.furycopy1 &&
                                contentOptions.furycopy1.field[contentOptions.currentLang]}
                            </li>
                            <li>
                              {contentOptions.furycopy2 &&
                                contentOptions.furycopy2.field[contentOptions.currentLang]}
                            </li>
                          </ul>
                        </div>
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.furycopy3 &&
                                contentOptions.furycopy3.field[contentOptions.currentLang]}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="l-row">
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.furycopy1 &&
                                contentOptions.furycopy1.field[contentOptions.currentLang]}
                            </li>
                            <li>
                              {contentOptions.furycopy2 &&
                                contentOptions.furycopy2.field[contentOptions.currentLang]}
                            </li>
                          </ul>
                        </div>
                        <div className="l-row__col l-1/2@md">
                          <ul>
                            <li>
                              {contentOptions.furycopy3 &&
                                contentOptions.furycopy3.field[contentOptions.currentLang]}
                            </li>
                            <li>
                              {contentOptions.furycopy4 &&
                                contentOptions.furycopy4.field[contentOptions.currentLang]}
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};
//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "configurator-results",
  component: S_ConfiguratorResults,
  navtxt: "Configurator Results",
  htmlName: "ConfiguratorResults",
  categoryType: ["internal"]
};
