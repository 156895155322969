import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../AppContext.jsx";
import S_ContentNav from "../../../3_sections/contentNav/S_ContentNav.jsx";
import S_Headline from "../../../3_sections/headline/S_Headline.jsx";

// Content Areas
import BrandColors from "./content/BrandColors.jsx";
import Photography from "./content/Photography.jsx";
import Video from "./content/Video.jsx";
import Logo from "./content/Logo.jsx";
import Tone from "./content/Tone.jsx";
import Typography from "./content/Typography.jsx";
import Commitment from "./content/Commitment.jsx";

export const Pg_BrandGuidelines = () => {
  // const cUrl = useLocation();
  // console.log("cUrl.pathname: ", cUrl.pathname);
  const { appState, setAppState } = useContext(AppContext);
  //now currentContent just sets active state for tab btns
  const [currentContent, setCurrentContent] = useState("logo");

  const navigate = useNavigate();
  let location = useLocation();
  //active_tab var to be able to track current tab and push url to browser
  const { active_tab } = useParams();
  let defaultTab = "logo";

  const toggleTab = (tab) => {
    if (active_tab !== tab) {
      navigate(`/brand/brand-guidelines/${tab}`);
    }
  };

  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/brand/brand-guidelines",
      outputName: "",
      description: "",
      headerName: "Brand Guidelines",
      viewHeight: null,
    });
  }, []);

  //this is to change the tab if it's changed from outside this page like search. Also allows forward and back on browser nav.
  useEffect(() => {
    let cPath = location.pathname;
    let tab = cPath.replace("/brand/brand-guidelines/", "");
    setCurrentContent(tab);
  }, [appState.currentPath, location]);

  useEffect(() => {
    if (!active_tab) {
      navigate(`/${defaultTab}`);
    }
    active_tab !== currentContent ? setCurrentContent(active_tab) : null;
    window.scrollTo(0, 0);
  }, [currentContent]);

  return (
    <div className="pg-brandElements u-flex u-flex1 u-flexColumn">
      <S_Headline
        classMods={"s-headline--alignLeft"}
        headline={appState.headerName}
        navigate={true}
      />
      <S_ContentNav
        tabs={[
          { name: "KTC Logo", active: "logo", path: "logo" },
          { name: "Brand Colors", active: "colors", path: "colors" },
          { name: "Typography", active: "typography", path: "typography" },
          { name: "Photography", active: "photography", path: "photography" },
          { name: "Video", active: "video", path: "video" },
          { name: "Tone of Voice", active: "tone", path: "tone" },
          { name: "Built on Commitment", active: "commitment", path: "commitment" },
        ]}
        updateContent={setCurrentContent}
        toggleTab={toggleTab}
        currentContent={currentContent}
        classMods={"s-contentNav--tabs"}
      />
      <div
        className="pg-brandElements__centerWrap u-flex u-flexColumn u-flex1 u-justifyCenter u-animated u-animated--delayFast u-animated--slower a-fadeIn"
        style={{ background: "white" }}
      >
        {currentContent == "logo" ? (
          <Logo />
        ) : currentContent == "colors" ? (
          <BrandColors />
        ) : currentContent == "typography" ? (
          <Typography />
        ) : currentContent == "tone" ? (
          <Tone />
        ) : currentContent == "commitment" ? (
          <Commitment />
        ) : currentContent == "photography" ? (
          <Photography />
        ) : currentContent == "video" ? (
          <Video />
        ) : currentContent == "video" ? (
          <Video />
        ) : (
          <Logo />
        )}
      </div>
    </div>
  );
};

export default {
  path: "brand-guidelines/:active_tab",
  component: Pg_BrandGuidelines,
  navtxt: "Brand Guidelines",
};
// export default Pg_BrandElements;
