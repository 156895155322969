export default {
  TaglineMode: {
    label: "Tagline Mode [Demo]",
    checkbox: null,
    field: ["Video", "Image"],
    selected: "Video"
  },
  Headline: {
    label: "Headline",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum",
      Span: "Kingston está contigo",
      ChS: "金士顿与你同在",
      Gr: "Kingston ist bei Ihnen",
      Ru: "Кингстон с тобой",
      Fr: "Kingston est avec vou",
      It: "Kingston è con te",
      Pl: "Kingston jest z tobą",
      Port: "Kingstone está com você",
      Viet: "Kingston luôn đồng hành cùng bạn",
      Turk: "Kingston yanınızda",
      Ukrn: "Кінгстон з вами",
      Thai: "คิงส์ตันอยู่กับคุณ",
      Kor: "킹스톤이 당신과 함께합니다",
      ChT: "金士頓與你同在",
      Jp: "キングストンはあなたと一緒です"
    },
    charLimit: 85
  },
  CTA: {
    label: "CTA",
    checkbox: true,
    field: {
      Eng: "Sed ut perspiciatis",
      Span: "Ver más",
      ChS: "查看更多",
      Gr: "Mehr sehen",
      Ru: "Узнать больше",
      Fr: "Voir plus",
      It: "Vedi altro",
      Pl: "Zobacz więcej",
      Port: "Ver mais",
      Viet: "Xem thêm",
      Turk: "Daha fazla gör",
      Ukrn: "Побачити більше",
      Thai: "ดูเพิ่มเติม",
      Kor: "더보기",
      ChT: "查看更多",
      Jp: "続きを見る"
    },
    charLimit: 85
  },
  Copy: {
    label: "Copy",
    checkbox: true,
    field: {
      Eng: "Iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋"
    },
    charLimit: 175
  },
  SVG: { label: "CTA Icon SVG", checkbox: true, field: "#play-basic" },
  Aligned: {
    label: "Clip Alignment",
    checkbox: null,
    field: ["Left (default)", "Lower Left", "Right", "Lower Right"],
    selected: "Left (default)"
  },
  Alternate: { label: "Enable Alternate (Light Mode)", checkbox: false, field: null },
  VideoL: {
    label: "Video Large",
    checkbox: null,
    field:
      "https://player.vimeo.com/video/451666754?muted=1&autoplay=1&autopause=0&controls=0&loop=1&background=1&app_id=122963"
  },
  VideoS: {
    label: "Video Small",
    checkbox: null,
    field: "https://vimeo.com/335022330"
  },
  currentLang: "Eng"
};
