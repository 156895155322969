export default {
  Headline: {
    label: "Headline",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum",
      Span: "Titular",
      ChS: "标题",
      Gr: "Überschrift",
      Ru: "Заголовок",
      Fr: "Gros titre",
      It: "Titolo",
      Pl: "Nagłówek",
      Port: "Título",
      Viet: "Tiêu đề",
      Turk: "Başlık",
      Ukrn: "Заголовок",
      Thai: "พาดหัว",
      Kor: "표제",
      ChT: "標題",
      Jp: "見出し"
    },
    charLimit: 35
  },
  HeadlineMode: {
    label: "Headline Mode",
    checkbox: null,
    field: ["DIV", "H1"],
    selected: "DIV"
  },
  CTA: {
    label: "CTA",
    checkbox: true,
    field: {
      Eng: "Sed ut perspiciatis",
      Span: "Ver más",
      ChS: "查看更多",
      Gr: "Mehr sehen",
      Ru: "Узнать больше",
      Fr: "Voir plus",
      It: "Vedi altro",
      Pl: "Zobacz więcej",
      Port: "Ver mais",
      Viet: "Xem thêm",
      Turk: "Daha fazla gör",
      Ukrn: "Побачити більше",
      Thai: "ดูเพิ่มเติม",
      Kor: "더보기",
      ChT: "查看更多",
      Jp: "続きを見る"
    },
    charLimit: 85
  },
  SVG: { label: "CTA Icon SVG", checkbox: true, field: "#play-basic" },
  Aligned: {
    label: "Clip Alignment",
    checkbox: null,
    field: ["Left (default)", "Lower Left", "Right", "Lower Right"],
    selected: "Left (default)"
  },
  Alternate: { label: "Enable Alternate (Light Mode)", checkbox: false, field: null },
  currentLang: "Eng"
};
